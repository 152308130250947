



















































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import vueQr from "vue-qr";
import {apiSendLists,apiSendDelay,apiSendFreeze,apiSendUnfreeze,apiSendDel,apiSendRepush} from "@/api/card_order/order";
import {RequestPaging} from "@/utils/util";
import ExportData from "@/components/export-data/index.vue";
@Component({
    components: { vueQr,ExportData },
})
export default class pointOrderDetail extends Vue {
	pager: RequestPaging = new RequestPaging();
	apiSendLists=apiSendLists;
	dialogleVisible=false;
	dialogleTitle="";
	status=0;
	loading=false;
	disabledYanqi=true;
	disabledDongjie=true;
	disabledJiedong=true;
	disabledZuofei=true;
	showYanqiDialog=false;
	showDongjieDialog=false;
	showJiedongDialog=false;
	showZuofeiDialog=false;

	searchObj: any = {
		tel: "",
		user_name: "",
		is_use: "",
		is_collect: "",
		status: "",
		page_no:1,
		page_size:20,
		order_id:0,
	};
	form :any={
		ids:[],
		expire_time:'',
		remark:'',
	}
	disableOld={
		disabledDate: (time:any) => {
			return time.getTime() < Date.now()- 8.64e7;
		}
	};
	created() {
		this.searchObj.order_id=this.$route.query.id
		this.getList();
	}
	// 列表
	getList(page?: number) {
        page && (this.pager.page = page);
		this.pager.request({
			callback: apiSendLists,
			params: {
				...this.searchObj,
			},
		});
	}
	// 重置搜索
	reset(): void {
		Object.keys(this.searchObj).map((key) => {
			if(key!='order_id') {
				this.$set(this.searchObj, key, "");
			}
		});
		this.getList(1);
	}

	multipleSelection: any[] = [];
	isSelectEmployee: boolean = true;
	allCheck: boolean = false;
	handleSelectionChange(val: any) {
		this.form.ids=[];
		this.multipleSelection = val;
		this.disabledZuofei=this.disabledJiedong=this.disabledYanqi=this.disabledDongjie=true;
		let status=[0,0,0];//已激活、已冻结、已作废
		let exprie=0;
		for(let k in this.multipleSelection){
			if(exprie<this.multipleSelection[k].expire_end)exprie=this.multipleSelection[k].expire_end;
			if(this.multipleSelection[k].status==1)status[0]=1;
			if(this.multipleSelection[k].status==4)status[1]=1;
			if(this.multipleSelection[k].status==2)status[2]=1;
			this.form.ids.push(this.multipleSelection[k].id);
		}
		if(this.multipleSelection.length>0) {
			this.disabledZuofei = status[2] == 1;
			this.disabledJiedong = status[0] == 1 || status[2] == 1;
			this.disabledYanqi =status[1] == 1 || status[2] == 1;
			this.disabledDongjie = status[1] == 1 || status[2] == 1;
			this.disableOld={
				disabledDate: (time:any) => {
					return time.getTime() <((new Date(exprie*1000+86400000)));
				}
			}
		}

	}
	reselectEmployees() {
		this.allCheck = false;
		this.multipleSelection = [];
		this.form.ids=[];
		this.isSelectEmployee = true;
	}
	allCheckbox(value: boolean) {
		this.allCheck = value;
		const tableRef = this.$refs.multipleTable as any;
		value ? tableRef.toggleAllSelection() : tableRef.clearSelection();
	}
	showD(status:number){
		this.status=status;
		console.log(status);
		switch(status){
			case 5:{
				this.dialogleTitle="延期";
				break;
			}
			case 4:{
				this.dialogleTitle="冻结";
				break;
			}
			case 1:{
				this.dialogleTitle="解冻";
				break;
			}
			case 2:{
				this.dialogleTitle="作废";
				break;
			}
		}
		this.dialogleVisible=true;
	}
	showYanqi(){
		this.showYanqiDialog=true;
	};
	showDongjie(){
		this.showDongjieDialog=true;
	};
	showJiedong(){
		this.showJiedongDialog=true;
	};
	showZuofei(){
		this.showZuofeiDialog=true;
	};
	submit_status=false;
	async activeYanqi(){
		if(this.submit_status)return;
		this.submit_status=true;
		await apiSendDelay(Object.assign(this.form,{status:5}))
		this.resetAll();
	};
	async activeDongjie(){
		if(this.submit_status)return;
		this.submit_status=true;
		await apiSendFreeze(Object.assign(this.form,{status:4}))
		this.resetAll();
	};
	async activeJiedong(){
		if(this.submit_status)return;
		this.submit_status=true;
		await apiSendUnfreeze(Object.assign(this.form,{status:1}))
		this.resetAll();
	};
	async activeZuofei(){
		if(this.submit_status)return;
		this.submit_status=true;
		await apiSendDel(Object.assign(this.form,{status:2}))
		this.resetAll();
	};
	resetAll(){
		this.form.ids=[];
		this.form.remark="";
		this.form.expire_time="";
		this.loading=false;
		this.showYanqiDialog=false;
		this.showDongjieDialog=false;
		this.showJiedongDialog=false;
		this.showZuofeiDialog=false;
		this.submit_status=false;
		this.getList();
	}
}
